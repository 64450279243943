"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _a, _b, _c, _d;
var ColorCode;
(function (ColorCode) {
    ColorCode["Black"] = "#000000";
    ColorCode["DarkGray"] = "#404040";
    ColorCode["Gray"] = "#808080";
    ColorCode["Silver"] = "#BFBFBF";
    ColorCode["White"] = "#FFFFFF";
    ColorCode["Yellow"] = "#FEFE00";
    ColorCode["Red"] = "#EE0000";
    ColorCode["Orange"] = "#FFA100";
    ColorCode["Blue"] = "#0000F2";
    ColorCode["Green"] = "#00D800";
    ColorCode["Purple"] = "#A83DD9";
    ColorCode["Pink"] = "#F33D7B";
    ColorCode["Transparent"] = "transparent";
})(ColorCode = exports.ColorCode || (exports.ColorCode = {}));
var ColorName;
(function (ColorName) {
    ColorName["Black"] = "black";
    ColorName["DarkGray"] = "darkGray";
    ColorName["Gray"] = "gray";
    ColorName["Silver"] = "silver";
    ColorName["White"] = "white";
    ColorName["Yellow"] = "yellow";
    ColorName["Red"] = "red";
    ColorName["Orange"] = "orange";
    ColorName["Blue"] = "blue";
    ColorName["Green"] = "green";
    ColorName["Purple"] = "purple";
    ColorName["Pink"] = "pink";
    ColorName["Transparent"] = "transparent";
})(ColorName = exports.ColorName || (exports.ColorName = {}));
var ColorAbbreviation;
(function (ColorAbbreviation) {
    ColorAbbreviation["Black"] = "n";
    ColorAbbreviation["DarkGray"] = "d";
    ColorAbbreviation["Gray"] = "l";
    ColorAbbreviation["Silver"] = "s";
    ColorAbbreviation["White"] = "w";
    ColorAbbreviation["Yellow"] = "y";
    ColorAbbreviation["Red"] = "r";
    ColorAbbreviation["Orange"] = "o";
    ColorAbbreviation["Blue"] = "b";
    ColorAbbreviation["Green"] = "g";
    ColorAbbreviation["Purple"] = "m";
    ColorAbbreviation["Pink"] = "p";
    ColorAbbreviation["Transparent"] = "t";
})(ColorAbbreviation = exports.ColorAbbreviation || (exports.ColorAbbreviation = {}));
var FaceletDefinition;
(function (FaceletDefinition) {
    FaceletDefinition["Up"] = "u";
    FaceletDefinition["Down"] = "d";
    FaceletDefinition["Left"] = "l";
    FaceletDefinition["Right"] = "r";
    FaceletDefinition["Back"] = "b";
    FaceletDefinition["Front"] = "f";
    FaceletDefinition["Transparent"] = "t";
    FaceletDefinition["Oriented"] = "o";
    FaceletDefinition["Blank"] = "n";
})(FaceletDefinition = exports.FaceletDefinition || (exports.FaceletDefinition = {}));
exports.ColorNameToCode = (_a = {},
    _a[ColorName.Black] = ColorCode.Black,
    _a[ColorName.DarkGray] = ColorCode.DarkGray,
    _a[ColorName.Gray] = ColorCode.Gray,
    _a[ColorName.Silver] = ColorCode.Silver,
    _a[ColorName.White] = ColorCode.White,
    _a[ColorName.Yellow] = ColorCode.Yellow,
    _a[ColorName.Red] = ColorCode.Red,
    _a[ColorName.Orange] = ColorCode.Orange,
    _a[ColorName.Blue] = ColorCode.Blue,
    _a[ColorName.Green] = ColorCode.Green,
    _a[ColorName.Purple] = ColorCode.Purple,
    _a[ColorName.Pink] = ColorCode.Pink,
    _a);
exports.ColorAbbreviationToCode = (_b = {},
    _b[ColorAbbreviation.Black] = ColorCode.Black,
    _b[ColorAbbreviation.DarkGray] = ColorCode.DarkGray,
    _b[ColorAbbreviation.Gray] = ColorCode.Gray,
    _b[ColorAbbreviation.Silver] = ColorCode.Silver,
    _b[ColorAbbreviation.White] = ColorCode.White,
    _b[ColorAbbreviation.Yellow] = ColorCode.Yellow,
    _b[ColorAbbreviation.Red] = ColorCode.Red,
    _b[ColorAbbreviation.Orange] = ColorCode.Orange,
    _b[ColorAbbreviation.Blue] = ColorCode.Blue,
    _b[ColorAbbreviation.Green] = ColorCode.Green,
    _b[ColorAbbreviation.Purple] = ColorCode.Purple,
    _b[ColorAbbreviation.Pink] = ColorCode.Pink,
    _b[ColorAbbreviation.Transparent] = ColorCode.Transparent,
    _b);
exports.FaceletAbbreviateToDefinition = {
    u: FaceletDefinition.Up,
    f: FaceletDefinition.Front,
    r: FaceletDefinition.Right,
    d: FaceletDefinition.Down,
    l: FaceletDefinition.Left,
    b: FaceletDefinition.Back,
    t: FaceletDefinition.Transparent,
    o: FaceletDefinition.Oriented,
    n: FaceletDefinition.Blank,
};
exports.FaceletToFace = (_c = {},
    _c[FaceletDefinition.Up] = 0 /* U */,
    _c[FaceletDefinition.Down] = 3 /* D */,
    _c[FaceletDefinition.Left] = 4 /* L */,
    _c[FaceletDefinition.Right] = 1 /* R */,
    _c[FaceletDefinition.Front] = 2 /* F */,
    _c[FaceletDefinition.Back] = 5 /* B */,
    _c);
exports.FaceletToColor = (_d = {},
    _d[FaceletDefinition.Oriented] = ColorName.Gray,
    _d[FaceletDefinition.Blank] = ColorCode.DarkGray,
    _d[FaceletDefinition.Transparent] = ColorName.Transparent,
    _d);
